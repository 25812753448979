import Simulator from './components/Simulator';
import './App.css';

function App() {
  return (
    <Simulator />
  );
}

export default App;
