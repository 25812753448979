import heic2any from 'heic2any';
import html2canvas from 'html2canvas';
import { useRef, useState } from 'react';

const defaultPic = 'default-pic.png';

export default function Simulator() {
    const poster = useRef<HTMLDivElement>(null);

    const [posterResult, setPosterResult] = useState<string>('');
    const [isLoading, setIsLoading] = useState<boolean>(false);
    const [image, setImage] = useState<string>(defaultPic);
    const [isImgLoading, setIsImgLoading] = useState<boolean>(false);
    const [title] = useState<string[]>([
        '读者',
        '某卫视首席观众',
        '某高校学生',
        '贝斯手',
        '小背包客',
        '敬业的酒吧掌柜',
        '非科班油画画师',
        '手鼓贩子',
        '专业皮匠',
        '专业银匠',
        '专业诗人',
        '萌新北京北漂',
        '资深丽江混混',
        '黄金右脸',
        '大冰老师弟子',
    ]);

    const handleSaveImg = () => {
        const target = poster.current;
        window?.gtag('event', 'save_img');
        if (target) {
            setIsLoading(true);
            html2canvas(target).then((canvas) => {
                const img = canvas.toDataURL('image/jpeg');

                setPosterResult(img);
                setIsLoading(false);
            });
        }
    };

    const handleImageUpload = async (
        event: React.ChangeEvent<HTMLInputElement>,
    ) => {
        const file = event.target.files?.[0];
        if (file) {
            if (file.type === 'image/heic' || file.type === 'image/heif') {
                // 将HEIC文件转换为JPEG
                try {
                    setIsImgLoading(true);
                    const conversionResult = await heic2any({
                        blob: file,
                        toType: 'image/jpeg',
                        quality: 0.8,
                    });

                    // 读取转换后的JPEG文件并设置预览
                    const reader = new FileReader();
                    reader.onloadend = () => {
                        setIsImgLoading(false);
                        setImage(reader.result as string);
                    };
                    reader.readAsDataURL(conversionResult as Blob);
                } catch (error) {
                    console.error('Error converting image', error);
                }
            } else {
                const reader = new FileReader();
                reader.onloadend = () => {
                    setImage(reader.result as string);
                };
                reader.onerror = () => {
                    alert('不支持的图片格式');
                    setImage(defaultPic);
                };
                reader.readAsDataURL(file);
            }
        }
    };

    return (
        <>
            <div className="text-white pt-safe-offset-10 w-screen bg-[#116CBB] flex flex-col items-center max-w-[798px] mx-auto">
                <div className="w-full flex flex-col items-center">
                    <h1 className="text-2xl font-bold">大冰模拟器（冰学简历图生成）</h1>
                    <div className="mt-1 text-sm text-white text-opacity-50">
                        点击下方任意文字均可编辑
                    </div>
                </div>
                <div
                    className="w-full flex flex-col items-center py-10 bg-[#116CBB]"
                    ref={poster}
                >
                    <div className="w-1/2">
                        <div className="w-full relative">
                            <div
                                className="bg-[#78A5EA]"
                                style={{
                                    paddingTop: '140%',
                                }}
                            ></div>
                            <div className="absolute w-full h-full top-0 flex justify-center items-center">
                                {image === defaultPic || isImgLoading ? (
                                    <div className="text-gray-300 text-7xl absolute left-0 right-0 top-0 bottom-0 m-auto w-full h-full flex flex-col items-center justify-center bg-black bg-opacity-50 z-10">
                                        {isImgLoading ? (
                                            <>
                                                <svg
                                                    className="animate-spin h-5 w-5 text-white"
                                                    xmlns="http://www.w3.org/2000/svg"
                                                    fill="none"
                                                    viewBox="0 0 24 24"
                                                >
                                                    <circle
                                                        className="opacity-25"
                                                        cx="12"
                                                        cy="12"
                                                        r="10"
                                                        stroke="currentColor"
                                                        stroke-width="4"
                                                    ></circle>
                                                    <path
                                                        className="opacity-75"
                                                        fill="currentColor"
                                                        d="M4 12a8 8 0 018-8V0C5.373 0 0 5.373 0 12h4zm2 5.291A7.962 7.962 0 014 12H0c0 3.042 1.135 5.824 3 7.938l3-2.647z"
                                                    ></path>
                                                </svg>
                                                <span className='text-sm mt-2'>HEIC 图片格式转换中</span>
                                            </>
                                        ) : (
                                            <div>+</div>
                                        )}
                                    </div>
                                ) : null}
                                <input
                                    className="w-full h-full opacity-0 cursor-pointer absolute top-0 left-0 z-20"
                                    type="file"
                                    accept="image/*"
                                    onChange={handleImageUpload}
                                />
                                {image && (
                                    <img
                                        src={image}
                                        className="w-full h-full object-cover"
                                        alt=""
                                    />
                                )}
                            </div>
                        </div>
                        <div className="mt-6">
                            <div className="text-xl font-black" contentEditable>
                                小冰，
                            </div>
                            <div className="mt-1" contentEditable>
                                1995年生人
                            </div>
                        </div>
                        <div
                            className="mt-4 font-extralight text-white text-opacity-80"
                            style={{
                                fontFamily:
                                    'Noto Serif SC, ui-sans-serif, system-ui, sans-serif, "Apple Color Emoji", "Segoe UI Emoji", "Segoe UI Symbol", "Noto Color Emoji"',
                            }}
                        >
                            <ul contentEditable>
                                {title.map((t) => (
                                    <li
                                        className="mt-2 font-light"
                                        style={{
                                            // clear li default
                                            listStyleType: 'none',
                                        }}
                                        key={t}
                                    >
                                        {t}
                                    </li>
                                ))}
                            </ul>
                        </div>
                        <div contentEditable className="mt-6">
                            新浪微博：@小冰
                        </div>
                        <div className="mt-10 text-white text-opacity-50 text-xs text-center">
                            - 大冰老师模拟器 -
                            <br />
                            <a href="https://www.hidabing.icu">
                                (www.hidabing.icu)
                            </a>
                        </div>
                    </div>
                </div>
                <div className="w-full h-40"></div>
                <div
                    className="fixed bottom-0 pb-safe-offset-10 w-full bg-opacity-50 backdrop-blur flex justify-center border-t border-white border-opacity-30"
                    style={{
                        background:
                            'linear-gradient(0deg, rgba(13, 58, 112, 0.5) 0%, rgba(13, 58, 112, 0) 100%)',
                    }}
                >
                    <button
                        className="mx-auto w-1/2 bg-white bg-opacity-30 py-4 rounded-lg mt-5 flex justify-center items-center"
                        onClick={handleSaveImg}
                    >
                        {isLoading ? (
                            <>
                                <span className="mr-2">本地生成中</span>
                                <svg
                                    className="animate-spin h-5 w-5 text-white"
                                    xmlns="http://www.w3.org/2000/svg"
                                    fill="none"
                                    viewBox="0 0 24 24"
                                >
                                    <circle
                                        className="opacity-25"
                                        cx="12"
                                        cy="12"
                                        r="10"
                                        stroke="currentColor"
                                        stroke-width="4"
                                    ></circle>
                                    <path
                                        className="opacity-75"
                                        fill="currentColor"
                                        d="M4 12a8 8 0 018-8V0C5.373 0 0 5.373 0 12h4zm2 5.291A7.962 7.962 0 014 12H0c0 3.042 1.135 5.824 3 7.938l3-2.647z"
                                    ></path>
                                </svg>
                            </>
                        ) : (
                            '保存为图片'
                        )}
                    </button>
                </div>
            </div>
            {posterResult && (
                <div
                    className="w-screen h-screen fixed top-0 left-0 flex flex-col justify-center items-center bg-black bg-opacity-80 z-50 px-10 py-20"
                    onClick={() => {
                        setPosterResult('');
                    }}
                >
                    <button
                        className="mt-5 bg-white bg-opacity-30 py-2 px-4 text-white rounded-full absolute top-10 right-5"
                        onClick={() => {
                            setPosterResult('');
                        }}
                    >
                        X
                    </button>
                    <div className="w-full h-full overflow-auto ">
                        <img
                            className="object-contain"
                            src={posterResult}
                            alt=""
                            onClick={(e) => e.stopPropagation()}
                        />
                    </div>

                    <span className="mt-5 text-white text-opacity-60">
                        长按图片保存
                    </span>
                </div>
            )}
        </>
    );
}
